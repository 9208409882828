// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-md-6\">\n        <h4>Topic Message Queue</h4>\n        <div class=\"alert alert-warning\">\n            <h4>Notice</h4> No producers exist for this topic.\n            <p>See <a href=\""
    + container.escapeExpression((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/lookup",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":23,"column":28},"end":{"line":23,"column":50}}}))
    + "\">Lookup</a> for more information.\n        </div>\n    </div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":0},"end":{"line":44,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"row\">\n    <div class=\"col-md-12\">\n    <h4>Topic Message Queue</h4>\n    <table class=\"table table-bordered table-condensed\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":55,"column":15}}})) != null ? stack1 : "")
    + "        <tr>\n            <th>NSQd Host</th>\n            <th>Depth</th>\n            <th>Memory + Disk</th>\n            <th>Messages</th>\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"graph_active") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":12},"end":{"line":61,"column":52}}})) != null ? stack1 : "")
    + "\n            <th>Channels</th>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1),{"name":"each","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":63,"column":12},"end":{"line":65,"column":21}}})) != null ? stack1 : "")
    + "        </tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"nodes") : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":8},"end":{"line":108,"column":17}}})) != null ? stack1 : "")
    + "        <tr class=\"info\">\n            <td>Total:</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":111,"column":16},"end":{"line":111,"column":33}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"memory_depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":112,"column":16},"end":{"line":112,"column":40}}}))
    + " + "
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"backend_depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":112,"column":43},"end":{"line":112,"column":68}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"message_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":113,"column":16},"end":{"line":113,"column":41}}}))
    + "</td>\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"graph_active") : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":114,"column":12},"end":{"line":114,"column":110}}})) != null ? stack1 : "")
    + "\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"channels") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":115,"column":16},"end":{"line":115,"column":43}}}))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":116,"column":12},"end":{"line":122,"column":19}}})) != null ? stack1 : "")
    + "        </tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"graph_active") : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":124,"column":8},"end":{"line":138,"column":15}}})) != null ? stack1 : "")
    + "    </table>\n    </div>\n</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row topic-actions\">\n    <div class=\"col-md-2\">\n        <button class=\"btn btn-medium btn-warning\" data-action=\"empty\">Empty Queue</button>\n    </div>\n    <div class=\"col-md-2\">\n        <button class=\"btn btn-medium btn-danger\" data-action=\"delete\">Delete Topic</button>\n    </div>\n    <div class=\"col-md-2\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"paused") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":37,"column":8},"end":{"line":41,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-medium btn-success\" data-action=\"unpause\">UnPause Topic</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-medium btn-primary\" data-action=\"pause\">Pause Topic</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr>\n            <th colspan=\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"graph_active") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data,"loc":{"start":{"line":52,"column":25},"end":{"line":52,"column":62}}})) != null ? stack1 : "")
    + "\"></th>\n            <th colspan=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">E2E Processing Latency</th>\n        </tr>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "6";
},"12":function(container,depth0,helpers,partials,data) {
    return "5";
},"14":function(container,depth0,helpers,partials,data) {
    return "<th>Rate</th>";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <th>"
    + alias3((lookupProperty(helpers,"floatToPercent")||(depth0 && lookupProperty(depth0,"floatToPercent"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"quantile") : depth0),{"name":"floatToPercent","hash":{},"data":data,"loc":{"start":{"line":64,"column":20},"end":{"line":64,"column":47}}}))
    + "<sup>"
    + alias3((lookupProperty(helpers,"percSuffix")||(depth0 && lookupProperty(depth0,"percSuffix"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"quantile") : depth0),{"name":"percSuffix","hash":{},"data":data,"loc":{"start":{"line":64,"column":52},"end":{"line":64,"column":75}}}))
    + "</sup></th>\n";
},"18":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr>\n            <td>\n                <button class=\"btn-link red tombstone-link\" data-node=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"node") || (depth0 != null ? lookupProperty(depth0,"node") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"node","hash":{},"data":data,"loc":{"start":{"line":70,"column":71},"end":{"line":70,"column":79}}}) : helper)))
    + "\" data-topic=\""
    + alias3(container.lambda((depths[1] != null ? lookupProperty(depths[1],"name") : depths[1]), depth0))
    + "\" style=\"padding: 0 6px; border: 0;\">✘</button>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_broadcast_address") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.program(21, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":71,"column":16},"end":{"line":75,"column":23}}})) != null ? stack1 : "")
    + "                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"paused") : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":76,"column":16},"end":{"line":76,"column":85}}})) != null ? stack1 : "")
    + "\n            </td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":78,"column":16},"end":{"line":78,"column":33}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"memory_depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":79,"column":16},"end":{"line":79,"column":40}}}))
    + " + "
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"backend_depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":79,"column":43},"end":{"line":79,"column":68}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"message_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":80,"column":16},"end":{"line":80,"column":41}}}))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"graph_active") : depths[1]),{"name":"if","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":12},"end":{"line":83,"column":19}}})) != null ? stack1 : "")
    + "            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"channels") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":84,"column":16},"end":{"line":84,"column":48}}}))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":12},"end":{"line":91,"column":19}}})) != null ? stack1 : "")
    + "        </tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"graph_active") : depths[1]),{"name":"if","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":93,"column":8},"end":{"line":107,"column":15}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias4(((helper = (helper = lookupProperty(helpers,"hostname_port") || (depth0 != null ? lookupProperty(depth0,"hostname_port") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hostname_port","hash":{},"data":data,"loc":{"start":{"line":72,"column":16},"end":{"line":72,"column":33}}}) : helper)))
    + " (<a class=\"link\" href=\""
    + alias4((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||alias2).call(alias1,"/nodes",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":72,"column":57},"end":{"line":72,"column":78}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"node") || (depth0 != null ? lookupProperty(depth0,"node") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"node","hash":{},"data":data,"loc":{"start":{"line":72,"column":79},"end":{"line":72,"column":87}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"node") || (depth0 != null ? lookupProperty(depth0,"node") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"node","hash":{},"data":data,"loc":{"start":{"line":72,"column":89},"end":{"line":72,"column":97}}}) : helper)))
    + "</a>)\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"link\" href=\""
    + alias3((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||alias2).call(alias1,"/nodes",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":74,"column":38},"end":{"line":74,"column":59}}}))
    + "/"
    + alias3(((helper = (helper = lookupProperty(helpers,"node") || (depth0 != null ? lookupProperty(depth0,"node") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"node","hash":{},"data":data,"loc":{"start":{"line":74,"column":60},"end":{"line":74,"column":68}}}) : helper)))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"hostname_port") || (depth0 != null ? lookupProperty(depth0,"hostname_port") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"hostname_port","hash":{},"data":data,"loc":{"start":{"line":74,"column":70},"end":{"line":74,"column":87}}}) : helper)))
    + "</a>\n";
},"23":function(container,depth0,helpers,partials,data) {
    return " <span class=\"label label-primary\">paused</span>";
},"25":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td class=\"bold rate\" target=\""
    + container.escapeExpression((lookupProperty(helpers,"rate")||(depth0 && lookupProperty(depth0,"rate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"topic",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),"",{"name":"rate","hash":{},"data":data,"loc":{"start":{"line":82,"column":46},"end":{"line":82,"column":81}}}))
    + "\"></td>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1),{"name":"each","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":86,"column":16},"end":{"line":90,"column":25}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td>\n                    <span title=\""
    + alias3((lookupProperty(helpers,"floatToPercent")||(depth0 && lookupProperty(depth0,"floatToPercent"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"quantile") : depth0),{"name":"floatToPercent","hash":{},"data":data,"loc":{"start":{"line":88,"column":33},"end":{"line":88,"column":60}}}))
    + ": min = "
    + alias3((lookupProperty(helpers,"nanotohuman")||(depth0 && lookupProperty(depth0,"nanotohuman"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"min") : depth0),{"name":"nanotohuman","hash":{},"data":data,"loc":{"start":{"line":88,"column":68},"end":{"line":88,"column":87}}}))
    + ", max = "
    + alias3((lookupProperty(helpers,"nanotohuman")||(depth0 && lookupProperty(depth0,"nanotohuman"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"max") : depth0),{"name":"nanotohuman","hash":{},"data":data,"loc":{"start":{"line":88,"column":95},"end":{"line":88,"column":114}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"nanotohuman")||(depth0 && lookupProperty(depth0,"nanotohuman"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"average") : depth0),{"name":"nanotohuman","hash":{},"data":data,"loc":{"start":{"line":88,"column":116},"end":{"line":88,"column":139}}}))
    + "</span>\n                </td>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr class=\"graph-row\">\n                <td></td>\n                <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"topic",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),"","depth",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":96,"column":29},"end":{"line":96,"column":79}}}))
    + "\"><img width=\"120\" src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"topic",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),"","depth",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":96,"column":103},"end":{"line":96,"column":151}}}))
    + "\"></a></td>\n                <td></td>\n                <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"topic",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),"","message_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":98,"column":29},"end":{"line":98,"column":87}}}))
    + "\"><img width=\"120\" src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"topic",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),"","message_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":98,"column":111},"end":{"line":98,"column":167}}}))
    + "\"></a></td>\n                <td></td>\n                <td></td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":101,"column":16},"end":{"line":105,"column":23}}})) != null ? stack1 : "")
    + "            </tr>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td colspan=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">\n                    <a href=\""
    + alias1((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias3).call(alias2,"e2e",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0),"","e2e_processing_latency",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":103,"column":29},"end":{"line":103,"column":106}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias1((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias3).call(alias2,"e2e",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0),"","e2e_processing_latency",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":103,"column":143},"end":{"line":103,"column":218}}}))
    + "\"></a>\n                </td>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"bold rate\" target=\""
    + container.escapeExpression((lookupProperty(helpers,"rate")||(depth0 && lookupProperty(depth0,"rate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"topic","*",(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),"",{"name":"rate","hash":{},"data":data,"loc":{"start":{"line":114,"column":62},"end":{"line":114,"column":96}}}))
    + "\"></td>";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr class=\"graph-row\">\n                <td></td>\n                <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"topic","*",(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),"","depth",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":127,"column":29},"end":{"line":127,"column":78}}}))
    + "\"><img width=\"120\" src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"topic",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),"","depth",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":127,"column":102},"end":{"line":127,"column":150}}}))
    + "\"></a></td>\n                <td></td>\n                <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"topic","*",(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),"","message_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":129,"column":29},"end":{"line":129,"column":86}}}))
    + "\"><img width=\"120\" src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"topic",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),"","message_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":129,"column":110},"end":{"line":129,"column":166}}}))
    + "\"></a></td>\n                <td></td>\n                <td></td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":16},"end":{"line":136,"column":23}}})) != null ? stack1 : "")
    + "            </tr>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td colspan=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">\n                    <a href=\""
    + alias1((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias3).call(alias2,"e2e","*",(depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0),"","e2e_processing_latency",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":134,"column":29},"end":{"line":134,"column":105}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias1((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias3).call(alias2,"e2e",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0),"","e2e_processing_latency",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":134,"column":142},"end":{"line":134,"column":217}}}))
    + "\"></a>\n                </td>\n";
},"38":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"col-md-6\">\n        <h4>Channel Message Queues</h4>\n        <div class=\"alert alert-warning\">\n            <h4>Notice</h4> No channels exist for this topic.\n            <p>Messages will queue at the topic until a channel is created.\n        </div>\n";
},"40":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"col-md-12\">\n        <h4>Channel Message Queues</h4>\n        <table class=\"table table-bordered table-condensed\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(41, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":157,"column":12},"end":{"line":162,"column":19}}})) != null ? stack1 : "")
    + "            <tr>\n                <th>Channel</th>\n                <th>Depth</th>\n                <th>Memory + Disk</th>\n                <th>In-Flight</th>\n                <th>Deferred</th>\n                <th>Requeued</th>\n                <th>Timed Out</th>\n                <th>Messages</th>\n                <th>Connections</th>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1),{"name":"each","hash":{},"fn":container.program(16, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":173,"column":16},"end":{"line":175,"column":25}}})) != null ? stack1 : "")
    + "            </tr>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"channels") : depth0),{"name":"each","hash":{},"fn":container.program(46, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":178,"column":12},"end":{"line":218,"column":21}}})) != null ? stack1 : "")
    + "        </table>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <th colspan=\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"graph_active") : depth0),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.program(44, data, 0),"data":data,"loc":{"start":{"line":159,"column":29},"end":{"line":159,"column":67}}})) != null ? stack1 : "")
    + "\"></th>\n                <th colspan=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">E2E Processing Latency</th>\n            </tr>\n";
},"42":function(container,depth0,helpers,partials,data) {
    return "10";
},"44":function(container,depth0,helpers,partials,data) {
    return "9";
},"46":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <th>\n                    <a class=\"link\" href=\""
    + alias3((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||alias2).call(alias1,"/topics",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":181,"column":42},"end":{"line":181,"column":64}}}))
    + "/"
    + alias3((lookupProperty(helpers,"urlencode")||(depth0 && lookupProperty(depth0,"urlencode"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),{"name":"urlencode","hash":{},"data":data,"loc":{"start":{"line":181,"column":65},"end":{"line":181,"column":89}}}))
    + "/"
    + alias3((lookupProperty(helpers,"urlencode")||(depth0 && lookupProperty(depth0,"urlencode"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),{"name":"urlencode","hash":{},"data":data,"loc":{"start":{"line":181,"column":90},"end":{"line":181,"column":116}}}))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"channel_name") || (depth0 != null ? lookupProperty(depth0,"channel_name") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"channel_name","hash":{},"data":data,"loc":{"start":{"line":181,"column":118},"end":{"line":181,"column":134}}}) : helper)))
    + "</a>\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"paused") : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":182,"column":20},"end":{"line":182,"column":88}}})) != null ? stack1 : "")
    + "\n                </th>\n                <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":184,"column":20},"end":{"line":184,"column":37}}}))
    + "</td>\n                <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"memory_depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":185,"column":20},"end":{"line":185,"column":44}}}))
    + " + "
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"backend_depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":185,"column":47},"end":{"line":185,"column":72}}}))
    + "</td>\n                <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"in_flight_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":186,"column":20},"end":{"line":186,"column":47}}}))
    + "</td>\n                <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"deferred_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":187,"column":20},"end":{"line":187,"column":46}}}))
    + "</td>\n                <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"requeue_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":188,"column":20},"end":{"line":188,"column":45}}}))
    + "</td>\n                <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"timeout_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":189,"column":20},"end":{"line":189,"column":45}}}))
    + "</td>\n                <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"message_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":190,"column":20},"end":{"line":190,"column":45}}}))
    + "</td>\n                <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"client_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":191,"column":20},"end":{"line":191,"column":44}}}))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(49, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":192,"column":16},"end":{"line":198,"column":23}}})) != null ? stack1 : "")
    + "            </tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"graph_active") : depths[1]),{"name":"if","hash":{},"fn":container.program(52, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":200,"column":12},"end":{"line":217,"column":19}}})) != null ? stack1 : "");
},"47":function(container,depth0,helpers,partials,data) {
    return "<span class=\"label label-primary\">paused</span>";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1),{"name":"each","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":193,"column":20},"end":{"line":197,"column":27}}})) != null ? stack1 : "");
},"50":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <td>\n                        <span title=\""
    + alias3((lookupProperty(helpers,"floatToPercent")||(depth0 && lookupProperty(depth0,"floatToPercent"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"quantile") : depth0),{"name":"floatToPercent","hash":{},"data":data,"loc":{"start":{"line":195,"column":37},"end":{"line":195,"column":64}}}))
    + ": min = "
    + alias3((lookupProperty(helpers,"nanotohuman")||(depth0 && lookupProperty(depth0,"nanotohuman"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"min") : depth0),{"name":"nanotohuman","hash":{},"data":data,"loc":{"start":{"line":195,"column":72},"end":{"line":195,"column":91}}}))
    + ", max = "
    + alias3((lookupProperty(helpers,"nanotohuman")||(depth0 && lookupProperty(depth0,"nanotohuman"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"max") : depth0),{"name":"nanotohuman","hash":{},"data":data,"loc":{"start":{"line":195,"column":99},"end":{"line":195,"column":118}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"nanotohuman")||(depth0 && lookupProperty(depth0,"nanotohuman"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"average") : depth0),{"name":"nanotohuman","hash":{},"data":data,"loc":{"start":{"line":195,"column":120},"end":{"line":195,"column":143}}}))
    + "</span>\n                    </td>\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr class=\"graph-row\">\n                <td></td>\n                <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"depth",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":203,"column":29},"end":{"line":203,"column":91}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"depth",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":203,"column":128},"end":{"line":203,"column":188}}}))
    + "\"></a></td>\n                <td></td>\n                <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"in_flight_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":205,"column":29},"end":{"line":205,"column":101}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"in_flight_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":205,"column":138},"end":{"line":205,"column":208}}}))
    + "\"></a></td>\n                <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"deferred_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":206,"column":29},"end":{"line":206,"column":100}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"deferred_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":206,"column":137},"end":{"line":206,"column":206}}}))
    + "\"></a></td>\n                <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"requeue_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":207,"column":29},"end":{"line":207,"column":99}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"requeue_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":207,"column":136},"end":{"line":207,"column":204}}}))
    + "\"></a></td>\n                <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"timeout_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":208,"column":29},"end":{"line":208,"column":99}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"timeout_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":208,"column":136},"end":{"line":208,"column":204}}}))
    + "\"></a></td>\n                <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"message_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":209,"column":29},"end":{"line":209,"column":99}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"message_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":209,"column":136},"end":{"line":209,"column":204}}}))
    + "\"></a></td>\n                <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"clients",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":210,"column":29},"end":{"line":210,"column":93}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"clients",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":210,"column":130},"end":{"line":210,"column":192}}}))
    + "\"></a></td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":211,"column":16},"end":{"line":215,"column":23}}})) != null ? stack1 : "")
    + "            </tr>\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <td colspan=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">\n                        <a href=\""
    + alias1((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias3).call(alias2,"e2e",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0),"","e2e_processing_latency",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":213,"column":33},"end":{"line":213,"column":110}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias1((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias3).call(alias2,"e2e",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0),"","e2e_processing_latency",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":213,"column":147},"end":{"line":213,"column":222}}}))
    + "\"></a>\n                    </td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"warning"),depth0,{"name":"warning","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"error"),depth0,{"name":"error","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<ol class=\"breadcrumb\">\n  <li><a class=\"link\" href=\""
    + alias3((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||alias2).call(alias1,"/",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":5,"column":28},"end":{"line":5,"column":44}}}))
    + "\">Streams</a>\n  <li class=\"active\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":6,"column":21},"end":{"line":6,"column":29}}}) : helper)))
    + "</li>\n</ol>\n\n<div class=\"row\">\n    <div class=\"col-md-6\">\n        <blockquote>\n            <p>Topic: <strong>"
    + alias3(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":12,"column":30},"end":{"line":12,"column":38}}}) : helper)))
    + "</strong>\n        </blockquote>\n    </div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nodes") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":17,"column":0},"end":{"line":142,"column":11}}})) != null ? stack1 : "")
    + "\n\n<div class=\"row\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"channels") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"unless","hash":{},"fn":container.program(38, data, 0, blockParams, depths),"inverse":container.program(40, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":146,"column":4},"end":{"line":220,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
