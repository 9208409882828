// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n  <div class=\"col-md-8 col-md-offset-2\">\n    <table class=\"table muted\">\n      <tr>\n        <td>\n          <a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"node",(depth0 != null ? lookupProperty(depth0,"node") : depth0),"","","*_bytes",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":15,"column":19},"end":{"line":15,"column":62}}}))
    + "\"><img class=\"img-polaroid\" width=\"200\" src=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"node",(depth0 != null ? lookupProperty(depth0,"node") : depth0),"","","*_bytes",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":15,"column":107},"end":{"line":15,"column":150}}}))
    + "\"/></a>\n          <h5 style=\"text-align: center;\">GC Pressure</h5>\n        </td>\n        <td>\n          <a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"node",(depth0 != null ? lookupProperty(depth0,"node") : depth0),"","","gc_pause_*",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":19,"column":19},"end":{"line":19,"column":65}}}))
    + "\"><img class=\"img-polaroid\" width=\"200\" src=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"node",(depth0 != null ? lookupProperty(depth0,"node") : depth0),"","","gc_pause_*",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":19,"column":110},"end":{"line":19,"column":156}}}))
    + "\"/></a>\n          <h5 style=\"text-align: center;\">GC Pause Percentiles</h5>\n        </td>\n        <td>\n          <a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"node",(depth0 != null ? lookupProperty(depth0,"node") : depth0),"","","gc_runs",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":23,"column":19},"end":{"line":23,"column":62}}}))
    + "\"><img class=\"img-polaroid\" width=\"200\" src=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"node",(depth0 != null ? lookupProperty(depth0,"node") : depth0),"","","gc_runs",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":23,"column":107},"end":{"line":23,"column":150}}}))
    + "\"/></a>\n          <h5 style=\"text-align: center;\">GC Runs</h5>\n        </td>\n        <td>\n          <a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"node",(depth0 != null ? lookupProperty(depth0,"node") : depth0),"","","heap_objects",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":27,"column":19},"end":{"line":27,"column":67}}}))
    + "\"><img class=\"img-polaroid\" width=\"200\" src=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"node",(depth0 != null ? lookupProperty(depth0,"node") : depth0),"","","heap_objects",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":27,"column":112},"end":{"line":27,"column":160}}}))
    + "\"/></a>\n          <h5 style=\"text-align: center;\">Heap Objects In-Use</h5>\n        </td>\n      </tr>\n    </table>\n  </div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"alert alert-warning\">\n            <h4>Notice</h4> No topics exist on this node.\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <table class=\"table table-condensed\">\n        <tr>\n            <td colspan=\"2\"><strong>"
    + alias1(container.lambda(((stack1 = (depths[1] != null ? lookupProperty(depths[1],"topics") : depths[1])) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "</strong> Topics</td>\n            <td colspan=\"7\"></td>\n            <td><strong>"
    + alias1((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias3).call(alias2,(depth0 != null ? lookupProperty(depth0,"total_messages") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":47,"column":24},"end":{"line":47,"column":50}}}))
    + "</strong> Messages</td>\n            <td><strong>"
    + alias1((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias3).call(alias2,(depth0 != null ? lookupProperty(depth0,"total_clients") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":48,"column":24},"end":{"line":48,"column":49}}}))
    + "</strong> Clients</td>\n        </tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias2,(depth0 != null ? lookupProperty(depth0,"topics") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":50,"column":8},"end":{"line":174,"column":17}}})) != null ? stack1 : "")
    + "    </table>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr>\n            <th colspan=\"3\">Topic</th>\n            <th>Depth</th>\n            <th>Memory + Disk</th>\n            <th colspan=\"4\"></th>\n            <th>Messages</th>\n            <th>Channels</th>\n        </tr>\n        <tr class=\"info\">\n            <td colspan=\"3\">\n                <button class=\"btn-link red tombstone-link\" data-node=\""
    + alias1(container.lambda((depths[1] != null ? lookupProperty(depths[1],"name") : depths[1]), depth0))
    + "\" data-topic=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"topic_name") || (depth0 != null ? lookupProperty(depth0,"topic_name") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":61,"column":96},"end":{"line":61,"column":110}}}) : helper)))
    + "\" style=\"padding: 0 6px; border: 0;\">✘</button> "
    + alias1(((helper = (helper = lookupProperty(helpers,"topic_name") || (depth0 != null ? lookupProperty(depth0,"topic_name") : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"topic_name","hash":{},"data":data,"loc":{"start":{"line":61,"column":158},"end":{"line":61,"column":172}}}) : helper)))
    + "\n            </td>\n            <td>\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depths[1] != null ? lookupProperty(depths[1],"graph_active") : depths[1]),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":64,"column":16},"end":{"line":64,"column":177}}})) != null ? stack1 : "")
    + "\n                "
    + alias1((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias3).call(alias2,(depth0 != null ? lookupProperty(depth0,"depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":65,"column":16},"end":{"line":65,"column":33}}}))
    + "\n            </td>\n            <td>"
    + alias1((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias3).call(alias2,(depth0 != null ? lookupProperty(depth0,"memory_depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":67,"column":16},"end":{"line":67,"column":40}}}))
    + " + "
    + alias1((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias3).call(alias2,(depth0 != null ? lookupProperty(depth0,"backend_depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":67,"column":43},"end":{"line":67,"column":68}}}))
    + "</td>\n            <td colspan=\"4\"></td>\n            <td>\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,(depths[1] != null ? lookupProperty(depths[1],"graph_active") : depths[1]),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":16},"end":{"line":70,"column":199}}})) != null ? stack1 : "")
    + "\n                "
    + alias1((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias3).call(alias2,(depth0 != null ? lookupProperty(depth0,"message_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":71,"column":16},"end":{"line":71,"column":41}}}))
    + "\n            </td>\n            <td>"
    + alias1((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias3).call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"channels") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":73,"column":16},"end":{"line":73,"column":43}}}))
    + "</td>\n        </tr>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,((stack1 = (depth0 != null ? lookupProperty(depth0,"channels") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"unless","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":75,"column":8},"end":{"line":173,"column":19}}})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"topic",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"name") : depth0),"","depth",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":64,"column":48},"end":{"line":64,"column":92}}}))
    + "\"><img width=\"120\" src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"topic",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),"","depth",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":64,"column":116},"end":{"line":64,"column":164}}}))
    + "\"></a>";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"topic",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),"","message_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":70,"column":48},"end":{"line":70,"column":106}}}))
    + "\"><img width=\"120\" src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"topic",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),"","message_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":70,"column":130},"end":{"line":70,"column":186}}}))
    + "\"></a>";
},"11":function(container,depth0,helpers,partials,data) {
    return "        <tr>\n            <td colspan=\"11\">\n              <div class=\"alert alert-warning\"><h4>Notice</h4> No channels exist for this topic.</div>\n            </td>\n        </tr>\n";
},"13":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"channels") : depth0),{"name":"each","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":8},"end":{"line":172,"column":17}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr>\n            <th width=\"25\"></th>\n            <th colspan=\"2\">Channel</th>\n            <th>Depth</th>\n            <th>Memory + Disk</th>\n            <th>In-Flight</th>\n            <th>Deferred</th>\n            <th>Requeued</th>\n            <th>Timed Out</th>\n            <th>Messages</th>\n            <th>Connections</th>\n        </tr>\n        <tr class=\"warning\">\n            <td></td>\n            <td colspan=\"2\">\n                "
    + alias3(((helper = (helper = lookupProperty(helpers,"channel_name") || (depth0 != null ? lookupProperty(depth0,"channel_name") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"channel_name","hash":{},"data":data,"loc":{"start":{"line":98,"column":16},"end":{"line":98,"column":32}}}) : helper)))
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"paused") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":16},"end":{"line":99,"column":84}}})) != null ? stack1 : "")
    + "\n            </td>\n            <td>\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[3] != null ? lookupProperty(depths[3],"graph_active") : depths[3]),{"name":"if","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":16},"end":{"line":102,"column":225}}})) != null ? stack1 : "")
    + "\n                "
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":103,"column":16},"end":{"line":103,"column":33}}}))
    + "\n            </td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"memory_depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":105,"column":16},"end":{"line":105,"column":40}}}))
    + " + "
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"backend_depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":105,"column":43},"end":{"line":105,"column":68}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"in_flight_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":106,"column":16},"end":{"line":106,"column":43}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"deferred_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":107,"column":16},"end":{"line":107,"column":42}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"requeue_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":108,"column":16},"end":{"line":108,"column":41}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"timeout_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":109,"column":16},"end":{"line":109,"column":41}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"message_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":110,"column":16},"end":{"line":110,"column":41}}}))
    + "</td>\n            <td>\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[3] != null ? lookupProperty(depths[3],"graph_active") : depths[3]),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":112,"column":16},"end":{"line":112,"column":229}}})) != null ? stack1 : "")
    + "\n                "
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"clients") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":113,"column":16},"end":{"line":113,"column":42}}}))
    + "\n            </td>\n        </tr>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"clients") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"unless","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.program(23, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":116,"column":8},"end":{"line":171,"column":19}}})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    return "<span class=\"label label-primary\">paused</span>";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"depth",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":102,"column":54},"end":{"line":102,"column":116}}}))
    + "\"><img width=\"120\" height=\"20\" src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"depth",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":102,"column":152},"end":{"line":102,"column":212}}}))
    + "\"></a>";
},"19":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"clients",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":112,"column":54},"end":{"line":112,"column":118}}}))
    + "\"><img width=\"120\" height=\"20\" src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"clients",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":112,"column":154},"end":{"line":112,"column":216}}}))
    + "\"></a>";
},"21":function(container,depth0,helpers,partials,data) {
    return "        <tr>\n            <td colspan=\"11\">\n                <div class=\"alert alert-warning\"><h4>Notice</h4>No clients connected to this channel.</div>\n            </td>\n        </tr>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr>\n            <th></th>\n            <th>Client Host</th>\n            <th>User-Agent</th>\n            <th></th>\n            <th>Attributes</th>\n            <th>In-Flight</th>\n            <th>Ready Count</th>\n            <th>Requeued</th>\n            <th>Finished</th>\n            <th>Messages</th>\n            <th>Connected</th>\n        </tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"clients") : depth0),{"name":"each","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":8},"end":{"line":170,"column":17}}})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr>\n            <td></td>\n            <td title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"remote_address") || (depth0 != null ? lookupProperty(depth0,"remote_address") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"remote_address","hash":{},"data":data,"loc":{"start":{"line":139,"column":23},"end":{"line":139,"column":41}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"hostname") || (depth0 != null ? lookupProperty(depth0,"hostname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hostname","hash":{},"data":data,"loc":{"start":{"line":139,"column":43},"end":{"line":139,"column":55}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_client_id") : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":139,"column":55},"end":{"line":139,"column":100}}})) != null ? stack1 : "")
    + "</td>\n            <td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"user_agent") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":140,"column":16},"end":{"line":140,"column":77}}})) != null ? stack1 : "")
    + "</td>\n            <td></td>\n            <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sample_rate") : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":16},"end":{"line":145,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tls") : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":146,"column":16},"end":{"line":148,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deflate") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":149,"column":16},"end":{"line":151,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"snappy") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":152,"column":16},"end":{"line":154,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"authed") : depth0),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":155,"column":16},"end":{"line":161,"column":23}}})) != null ? stack1 : "")
    + "            </td>\n            <td>"
    + alias4((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"in_flight_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":163,"column":16},"end":{"line":163,"column":43}}}))
    + "</td>\n            <td>"
    + alias4((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ready_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":164,"column":16},"end":{"line":164,"column":39}}}))
    + "</td>\n            <td>"
    + alias4((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"requeue_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":165,"column":16},"end":{"line":165,"column":41}}}))
    + "</td>\n            <td>"
    + alias4((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"finish_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":166,"column":16},"end":{"line":166,"column":40}}}))
    + "</td>\n            <td>"
    + alias4((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"message_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":167,"column":16},"end":{"line":167,"column":41}}}))
    + "</td>\n            <td>"
    + alias4((lookupProperty(helpers,"nanotohuman")||(depth0 && lookupProperty(depth0,"nanotohuman"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"connected") : depth0),{"name":"nanotohuman","hash":{},"data":data,"loc":{"start":{"line":168,"column":16},"end":{"line":168,"column":41}}}))
    + "</td>\n        </tr>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ("
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"client_id") || (depth0 != null ? lookupProperty(depth0,"client_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"client_id","hash":{},"data":data,"loc":{"start":{"line":139,"column":79},"end":{"line":139,"column":92}}}) : helper)))
    + ")";
},"27":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<small>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"user_agent") || (depth0 != null ? lookupProperty(depth0,"user_agent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"user_agent","hash":{},"data":data,"loc":{"start":{"line":140,"column":48},"end":{"line":140,"column":62}}}) : helper)))
    + "</small>";
},"29":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"label label-info\">Sampled "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"sample_rate") || (depth0 != null ? lookupProperty(depth0,"sample_rate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"sample_rate","hash":{},"data":data,"loc":{"start":{"line":144,"column":59},"end":{"line":144,"column":74}}}) : helper)))
    + "%</span>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"label label-warning\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tls_version") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":147,"column":54},"end":{"line":147,"column":197}}})) != null ? stack1 : "")
    + ">TLS</span>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"tls_version") || (depth0 != null ? lookupProperty(depth0,"tls_version") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_version","hash":{},"data":data,"loc":{"start":{"line":147,"column":80},"end":{"line":147,"column":95}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"tls_cipher_suite") || (depth0 != null ? lookupProperty(depth0,"tls_cipher_suite") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_cipher_suite","hash":{},"data":data,"loc":{"start":{"line":147,"column":96},"end":{"line":147,"column":116}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"tls_negotiated_protocol") || (depth0 != null ? lookupProperty(depth0,"tls_negotiated_protocol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_negotiated_protocol","hash":{},"data":data,"loc":{"start":{"line":147,"column":117},"end":{"line":147,"column":144}}}) : helper)))
    + " mutual:"
    + alias4(((helper = (helper = lookupProperty(helpers,"tls_negotiated_protocol_is_mutual") || (depth0 != null ? lookupProperty(depth0,"tls_negotiated_protocol_is_mutual") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_negotiated_protocol_is_mutual","hash":{},"data":data,"loc":{"start":{"line":147,"column":152},"end":{"line":147,"column":189}}}) : helper)))
    + "\"";
},"34":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"label label-default\">Deflate</span>\n";
},"36":function(container,depth0,helpers,partials,data) {
    return "                    <span class=\"label label-primary\">Snappy</span>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"label label-success\">\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"auth_identity_url") : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":157,"column":20},"end":{"line":157,"column":84}}})) != null ? stack1 : "")
    + "\n                    <span class=\"glyphicon glyphicon-user white\" title=\"Authed"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"auth_identity") : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":158,"column":78},"end":{"line":158,"column":133}}})) != null ? stack1 : "")
    + "\"></span>\n                    "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"auth_identity_url") : depth0),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":159,"column":20},"end":{"line":159,"column":56}}})) != null ? stack1 : "")
    + "\n                    </span>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"auth_identity_url") || (depth0 != null ? lookupProperty(depth0,"auth_identity_url") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"auth_identity_url","hash":{},"data":data,"loc":{"start":{"line":157,"column":54},"end":{"line":157,"column":75}}}) : helper)))
    + "\">";
},"41":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " Identity:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"auth_identity") || (depth0 != null ? lookupProperty(depth0,"auth_identity") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"auth_identity","hash":{},"data":data,"loc":{"start":{"line":158,"column":109},"end":{"line":158,"column":126}}}) : helper)));
},"43":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"warning"),depth0,{"name":"warning","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"error"),depth0,{"name":"error","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<ol class=\"breadcrumb\">\n    <li><a class=\"link\" href=\""
    + alias3((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||alias2).call(alias1,"/nodes",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":5,"column":30},"end":{"line":5,"column":51}}}))
    + "\">Nodes</a>\n    <li class=\"active\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":6,"column":23},"end":{"line":6,"column":31}}}) : helper)))
    + "</li>\n</ol>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"graph_active") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":0},"end":{"line":34,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"row\">\n    <div class=\"col-md-12\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"topics") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"unless","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":38,"column":4},"end":{"line":176,"column":15}}})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
