// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <table class=\"table table-condensed table-bordered\">\n            <tr>\n                <th>Topic</th>\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"graph_active") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":16},"end":{"line":16,"column":69}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"graph_active") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":16},"end":{"line":17,"column":72}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"graph_active") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":18,"column":16},"end":{"line":18,"column":68}}})) != null ? stack1 : "")
    + "\n            </tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"collection") : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":12},"end":{"line":27,"column":21}}})) != null ? stack1 : "")
    + "        </table>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "<th width=\"120\">Depth</th>";
},"4":function(container,depth0,helpers,partials,data) {
    return "<th width=\"120\">Messages</th>";
},"6":function(container,depth0,helpers,partials,data) {
    return "<th width=\"120\">Rate</th>";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <td><a class=\"link\" href=\""
    + alias3((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||alias2).call(alias1,"/topics",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":22,"column":42},"end":{"line":22,"column":64}}}))
    + "/"
    + alias3((lookupProperty(helpers,"urlencode")||(depth0 && lookupProperty(depth0,"urlencode"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"urlencode","hash":{},"data":data,"loc":{"start":{"line":22,"column":65},"end":{"line":22,"column":83}}}))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":22,"column":85},"end":{"line":22,"column":93}}}) : helper)))
    + "</a></td>\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"graph_active") : depths[1]),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":200}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"graph_active") : depths[1]),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":16},"end":{"line":24,"column":208}}})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"graph_active") : depths[1]),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":111}}})) != null ? stack1 : "")
    + "\n            </tr>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td><a class=\"link\" href=\""
    + alias3((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||alias2).call(alias1,"/topics",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":23,"column":65},"end":{"line":23,"column":87}}}))
    + "/"
    + alias3((lookupProperty(helpers,"urlencode")||(depth0 && lookupProperty(depth0,"urlencode"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"urlencode","hash":{},"data":data,"loc":{"start":{"line":23,"column":88},"end":{"line":23,"column":106}}}))
    + "\"><img width=\"120\" height=\"20\" src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"topic","",(depth0 != null ? lookupProperty(depth0,"name") : depth0),"","depth",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":23,"column":142},"end":{"line":23,"column":182}}}))
    + "\"></a></td>";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td><a class=\"link\" href=\""
    + alias3((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||alias2).call(alias1,"/topics",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":24,"column":65},"end":{"line":24,"column":87}}}))
    + "/"
    + alias3((lookupProperty(helpers,"urlencode")||(depth0 && lookupProperty(depth0,"urlencode"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"name") : depth0),{"name":"urlencode","hash":{},"data":data,"loc":{"start":{"line":24,"column":88},"end":{"line":24,"column":106}}}))
    + "\"><img width=\"120\" height=\"20\" src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"topic","",(depth0 != null ? lookupProperty(depth0,"name") : depth0),"","message_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":24,"column":142},"end":{"line":24,"column":190}}}))
    + "\"></a></td>";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"bold rate\" target=\""
    + container.escapeExpression((lookupProperty(helpers,"rate")||(depth0 && lookupProperty(depth0,"rate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"topic","*",(depth0 != null ? lookupProperty(depth0,"name") : depth0),"",{"name":"rate","hash":{},"data":data,"loc":{"start":{"line":25,"column":69},"end":{"line":25,"column":97}}}))
    + "\"></td>";
},"15":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"alert alert-warning\"><h4>Notice</h4>No Topics Found</div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"warning"),depth0,{"name":"warning","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"error"),depth0,{"name":"error","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"row\">\n    <div class=\"col-md-12\">\n        <h2>Topics</h2>\n    </div>\n</div>\n\n<div class=\"row\">\n    <div class=\"col-md-6\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"collection") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":12,"column":4},"end":{"line":31,"column":11}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
