// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    return "                <th>Lookupd Conns.</th>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"out_of_date") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":57}}})) != null ? stack1 : "")
    + ">\n                <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"hostname") || (depth0 != null ? lookupProperty(depth0,"hostname") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hostname","hash":{},"data":data,"loc":{"start":{"line":26,"column":20},"end":{"line":26,"column":32}}}) : helper)))
    + "</td>\n                <td><a class=\"link\" href=\""
    + alias4((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||alias2).call(alias1,"/nodes",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":27,"column":42},"end":{"line":27,"column":63}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"broadcast_address_http") || (depth0 != null ? lookupProperty(depth0,"broadcast_address_http") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"broadcast_address_http","hash":{},"data":data,"loc":{"start":{"line":27,"column":64},"end":{"line":27,"column":90}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"broadcast_address") || (depth0 != null ? lookupProperty(depth0,"broadcast_address") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"broadcast_address","hash":{},"data":data,"loc":{"start":{"line":27,"column":92},"end":{"line":27,"column":113}}}) : helper)))
    + "</a></td>\n                <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"tcp_port") || (depth0 != null ? lookupProperty(depth0,"tcp_port") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tcp_port","hash":{},"data":data,"loc":{"start":{"line":28,"column":20},"end":{"line":28,"column":32}}}) : helper)))
    + "</td>\n                <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"http_port") || (depth0 != null ? lookupProperty(depth0,"http_port") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"http_port","hash":{},"data":data,"loc":{"start":{"line":29,"column":20},"end":{"line":29,"column":33}}}) : helper)))
    + "</td>\n                <td>"
    + alias4(((helper = (helper = lookupProperty(helpers,"version") || (depth0 != null ? lookupProperty(depth0,"version") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"version","hash":{},"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":30,"column":31}}}) : helper)))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depths[1] != null ? lookupProperty(depths[1],"nsqlookupd") : depths[1])) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":16},"end":{"line":38,"column":23}}})) != null ? stack1 : "")
    + "                <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"topics") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":16},"end":{"line":45,"column":23}}})) != null ? stack1 : "")
    + "                </td>\n            </tr>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "class=\"warning\"";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td>\n                    <a class=\"conn-count btn btn-default btn-xs "
    + ((stack1 = (lookupProperty(helpers,"unlesseq")||(depth0 && lookupProperty(depth0,"unlesseq"))||container.hooks.helperMissing).call(alias1,((stack1 = (depths[2] != null ? lookupProperty(depths[2],"nsqlookupd") : depths[2])) != null ? lookupProperty(stack1,"length") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"remote_addresses") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"unlesseq","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":64},"end":{"line":33,"column":149}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"remote_addresses") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "</a>\n                    <div style=\"display: none;\">\n                        "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"remote_addresses") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":24},"end":{"line":35,"column":72}}})) != null ? stack1 : "")
    + "\n                    </div>\n                </td>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "btn-warning";
},"9":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(depth0, depth0))
    + "<br/>";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <span class=\"badge\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"topics") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "</span>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"topics") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":42,"column":20},"end":{"line":44,"column":29}}})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <a href=\""
    + alias3((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||alias2).call(alias1,"/topics",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":43,"column":29},"end":{"line":43,"column":51}}}))
    + "/"
    + alias3(((helper = (helper = lookupProperty(helpers,"topic") || (depth0 != null ? lookupProperty(depth0,"topic") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"topic","hash":{},"data":data,"loc":{"start":{"line":43,"column":52},"end":{"line":43,"column":61}}}) : helper)))
    + "\" class=\"link label "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tombstoned") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":43,"column":81},"end":{"line":43,"column":140}}})) != null ? stack1 : "")
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tombstoned") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":142},"end":{"line":43,"column":222}}})) != null ? stack1 : "")
    + ">"
    + alias3(((helper = (helper = lookupProperty(helpers,"topic") || (depth0 != null ? lookupProperty(depth0,"topic") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"topic","hash":{},"data":data,"loc":{"start":{"line":43,"column":223},"end":{"line":43,"column":232}}}) : helper)))
    + "</a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "label-warning";
},"15":function(container,depth0,helpers,partials,data) {
    return "label-primary";
},"17":function(container,depth0,helpers,partials,data) {
    return "title=\"this topic is currently tombstoned on this node\"";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"warning"),depth0,{"name":"warning","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"error"),depth0,{"name":"error","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<div class=\"row\">\n    <div class=\"col-md-12\">\n        <h2>NSQd Nodes ("
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? lookupProperty(depth0,"collection") : depth0)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + ")</h2>\n    </div>\n</div>\n\n<div class=\"row\">\n    <div class=\"col-md-12\">\n        <table class=\"table table-condensed table-bordered\">\n            <tr>\n                <th>Hostname</th>\n                <th>Broadcast Address</th>\n                <th>TCP Port</th>\n                <th>HTTP Port</th>\n                <th>Version</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nsqlookupd") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":21,"column":23}}})) != null ? stack1 : "")
    + "                <th>Topics</th>\n            </tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"collection") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":12},"end":{"line":48,"column":21}}})) != null ? stack1 : "")
    + "        </table>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
