// hbsfy compiled Handlebars template
var HandlebarsCompiler = require('hbsfy/runtime');
module.exports = HandlebarsCompiler.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row\">\n    <div class=\"col-md-6\">\n        <div class=\"alert alert-warning\">\n            <h4>Notice</h4> No producers exist for this topic/channel.\n            <p>See <a class=\"link\" href=\""
    + container.escapeExpression((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"/lookup",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":24,"column":41},"end":{"line":24,"column":63}}}))
    + "\">Lookup</a> for more information.\n        </div>\n    </div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isAdmin") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":0},"end":{"line":45,"column":7}}})) != null ? stack1 : "")
    + "\n<div class=\"row\">\n    <div class=\"col-md-12\">\n    <h4>Channel</h4>\n    <table class=\"table table-bordered table-condensed\">\n        <tr>\n            <th>&nbsp;</th>\n            <th colspan=\"4\" class=\"text-center\">Message Queues</th>\n            <th colspan=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"graph_active") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.program(11, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":54,"column":25},"end":{"line":54,"column":62}}})) != null ? stack1 : "")
    + "\" class=\"text-center\">Statistics</th>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":12},"end":{"line":57,"column":19}}})) != null ? stack1 : "")
    + "        </tr>\n        <tr>\n            <th>NSQd Host</th>\n            <th>Depth</th>\n            <th>Memory + Disk</th>\n            <th>In-Flight</th>\n            <th>Deferred</th>\n            <th>Requeued</th>\n            <th>Timed Out</th>\n            <th>Messages</th>\n            "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"graph_active") : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":12},"end":{"line":68,"column":52}}})) != null ? stack1 : "")
    + "\n            <th>Connections</th>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1),{"name":"each","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":70,"column":12},"end":{"line":72,"column":21}}})) != null ? stack1 : "")
    + "        </tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"nodes") : depth0),{"name":"each","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":8},"end":{"line":122,"column":17}}})) != null ? stack1 : "")
    + "        <tr class=\"info\">\n            <td>Total:</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":125,"column":16},"end":{"line":125,"column":33}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"memory_depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":126,"column":16},"end":{"line":126,"column":40}}}))
    + " + "
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"backend_depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":126,"column":43},"end":{"line":126,"column":68}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"in_flight_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":127,"column":16},"end":{"line":127,"column":43}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"deferred_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":128,"column":16},"end":{"line":128,"column":42}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"requeue_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":129,"column":16},"end":{"line":129,"column":41}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"timeout_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":130,"column":16},"end":{"line":130,"column":41}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"message_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":131,"column":16},"end":{"line":131,"column":41}}}))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"graph_active") : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":132,"column":12},"end":{"line":134,"column":19}}})) != null ? stack1 : "")
    + "            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"client_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":135,"column":16},"end":{"line":135,"column":40}}}))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":12},"end":{"line":142,"column":19}}})) != null ? stack1 : "")
    + "        </tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"graph_active") : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":144,"column":8},"end":{"line":162,"column":15}}})) != null ? stack1 : "")
    + "    </table>\n    </div>\n</div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"row channel-actions\">\n    <div class=\"col-md-2\">\n        <button class=\"btn btn-medium btn-warning\" data-action=\"empty\">Empty Queue</button>\n    </div>\n    <div class=\"col-md-2\">\n        <button class=\"btn btn-medium btn-danger\" data-action=\"delete\">Delete Channel</button>\n    </div>\n    <div class=\"col-md-2\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"paused") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":38,"column":8},"end":{"line":42,"column":15}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-medium btn-success\" data-action=\"unpause\">UnPause Channel</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "        <button class=\"btn btn-medium btn-primary\" data-action=\"pause\">Pause Channel</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "5";
},"11":function(container,depth0,helpers,partials,data) {
    return "4";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <th colspan=\""
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">E2E Processing Latency</th>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "<th>Rate</th>";
},"17":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <th>"
    + alias3((lookupProperty(helpers,"floatToPercent")||(depth0 && lookupProperty(depth0,"floatToPercent"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"quantile") : depth0),{"name":"floatToPercent","hash":{},"data":data,"loc":{"start":{"line":71,"column":20},"end":{"line":71,"column":47}}}))
    + "<sup>"
    + alias3((lookupProperty(helpers,"percSuffix")||(depth0 && lookupProperty(depth0,"percSuffix"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"quantile") : depth0),{"name":"percSuffix","hash":{},"data":data,"loc":{"start":{"line":71,"column":52},"end":{"line":71,"column":75}}}))
    + "</sup></th>\n";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr>\n            <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_broadcast_address") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.program(22, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":77,"column":16},"end":{"line":81,"column":23}}})) != null ? stack1 : "")
    + "                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"paused") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":82,"column":16},"end":{"line":82,"column":85}}})) != null ? stack1 : "")
    + "\n            </td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":84,"column":16},"end":{"line":84,"column":33}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"memory_depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":85,"column":16},"end":{"line":85,"column":40}}}))
    + " + "
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"backend_depth") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":85,"column":43},"end":{"line":85,"column":68}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"in_flight_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":86,"column":16},"end":{"line":86,"column":43}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"deferred_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":87,"column":16},"end":{"line":87,"column":42}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"requeue_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":88,"column":16},"end":{"line":88,"column":41}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"timeout_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":89,"column":16},"end":{"line":89,"column":41}}}))
    + "</td>\n            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"message_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":90,"column":16},"end":{"line":90,"column":41}}}))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"graph_active") : depths[1]),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":91,"column":12},"end":{"line":93,"column":19}}})) != null ? stack1 : "")
    + "            <td>"
    + alias3((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"client_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":94,"column":16},"end":{"line":94,"column":40}}}))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":95,"column":12},"end":{"line":101,"column":19}}})) != null ? stack1 : "")
    + "        </tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depths[1] != null ? lookupProperty(depths[1],"graph_active") : depths[1]),{"name":"if","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":8},"end":{"line":121,"column":15}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + alias4(((helper = (helper = lookupProperty(helpers,"hostname_port") || (depth0 != null ? lookupProperty(depth0,"hostname_port") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hostname_port","hash":{},"data":data,"loc":{"start":{"line":78,"column":16},"end":{"line":78,"column":33}}}) : helper)))
    + " (<a class=\"link\" href=\""
    + alias4((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||alias2).call(alias1,"/nodes",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":78,"column":57},"end":{"line":78,"column":78}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"node") || (depth0 != null ? lookupProperty(depth0,"node") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"node","hash":{},"data":data,"loc":{"start":{"line":78,"column":79},"end":{"line":78,"column":87}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"node") || (depth0 != null ? lookupProperty(depth0,"node") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"node","hash":{},"data":data,"loc":{"start":{"line":78,"column":89},"end":{"line":78,"column":97}}}) : helper)))
    + "</a>)\n";
},"22":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"link\" href=\""
    + alias3((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||alias2).call(alias1,"/nodes",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":80,"column":38},"end":{"line":80,"column":59}}}))
    + "/"
    + alias3(((helper = (helper = lookupProperty(helpers,"node") || (depth0 != null ? lookupProperty(depth0,"node") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"node","hash":{},"data":data,"loc":{"start":{"line":80,"column":60},"end":{"line":80,"column":68}}}) : helper)))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"hostname_port") || (depth0 != null ? lookupProperty(depth0,"hostname_port") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"hostname_port","hash":{},"data":data,"loc":{"start":{"line":80,"column":70},"end":{"line":80,"column":87}}}) : helper)))
    + "</a>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return " <span class=\"label label-primary\">paused</span>";
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td class=\"bold rate\" target=\""
    + container.escapeExpression((lookupProperty(helpers,"rate")||(depth0 && lookupProperty(depth0,"rate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"topic",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),"",{"name":"rate","hash":{},"data":data,"loc":{"start":{"line":92,"column":46},"end":{"line":92,"column":81}}}))
    + "\"></td>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1),{"name":"each","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":16},"end":{"line":100,"column":25}}})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <td>\n                    <span title=\""
    + alias3((lookupProperty(helpers,"floatToPercent")||(depth0 && lookupProperty(depth0,"floatToPercent"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"quantile") : depth0),{"name":"floatToPercent","hash":{},"data":data,"loc":{"start":{"line":98,"column":33},"end":{"line":98,"column":60}}}))
    + ": min = "
    + alias3((lookupProperty(helpers,"nanotohuman")||(depth0 && lookupProperty(depth0,"nanotohuman"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"min") : depth0),{"name":"nanotohuman","hash":{},"data":data,"loc":{"start":{"line":98,"column":68},"end":{"line":98,"column":87}}}))
    + ", max = "
    + alias3((lookupProperty(helpers,"nanotohuman")||(depth0 && lookupProperty(depth0,"nanotohuman"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"max") : depth0),{"name":"nanotohuman","hash":{},"data":data,"loc":{"start":{"line":98,"column":95},"end":{"line":98,"column":114}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"nanotohuman")||(depth0 && lookupProperty(depth0,"nanotohuman"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"average") : depth0),{"name":"nanotohuman","hash":{},"data":data,"loc":{"start":{"line":98,"column":116},"end":{"line":98,"column":139}}}))
    + "</span>\n                </td>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr class=\"graph-row\">\n            <td></td>\n            <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"depth",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":106,"column":25},"end":{"line":106,"column":87}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"depth",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":106,"column":124},"end":{"line":106,"column":184}}}))
    + "\"></a></td>\n            <td></td>\n            <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"in_flight_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":108,"column":25},"end":{"line":108,"column":97}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"in_flight_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":108,"column":134},"end":{"line":108,"column":204}}}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"deferred_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":109,"column":25},"end":{"line":109,"column":96}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"deferred_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":109,"column":133},"end":{"line":109,"column":202}}}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"requeue_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":110,"column":25},"end":{"line":110,"column":95}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"requeue_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":110,"column":132},"end":{"line":110,"column":200}}}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"timeout_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":111,"column":25},"end":{"line":111,"column":95}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"timeout_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":111,"column":132},"end":{"line":111,"column":200}}}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"message_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":112,"column":25},"end":{"line":112,"column":95}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"message_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":112,"column":132},"end":{"line":112,"column":200}}}))
    + "\"></a></td>\n            <td></td>\n            <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"clients",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":114,"column":25},"end":{"line":114,"column":89}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"clients",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":114,"column":126},"end":{"line":114,"column":188}}}))
    + "\"></a></td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":12},"end":{"line":119,"column":19}}})) != null ? stack1 : "")
    + "        </tr>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <td colspan=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">\n                <a href=\""
    + alias1((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias3).call(alias2,"e2e",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0),"","e2e_processing_latency",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":117,"column":25},"end":{"line":117,"column":102}}}))
    + "\"><img width=\"120\" height=\"20\" src=\""
    + alias1((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias3).call(alias2,"e2e",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0),"","e2e_processing_latency",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":117,"column":138},"end":{"line":117,"column":213}}}))
    + "\"></a>\n            </td>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <tr class=\"graph-row\">\n            <td></td>\n            <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"depth",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":147,"column":25},"end":{"line":147,"column":87}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"depth",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":147,"column":124},"end":{"line":147,"column":184}}}))
    + "\"></a></td>\n            <td></td>\n            <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"in_flight_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":149,"column":25},"end":{"line":149,"column":97}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"in_flight_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":149,"column":134},"end":{"line":149,"column":204}}}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"deferred_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":150,"column":25},"end":{"line":150,"column":96}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"deferred_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":150,"column":133},"end":{"line":150,"column":202}}}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"requeue_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":151,"column":25},"end":{"line":151,"column":95}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"requeue_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":151,"column":132},"end":{"line":151,"column":200}}}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"timeout_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":152,"column":25},"end":{"line":152,"column":95}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"timeout_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":152,"column":132},"end":{"line":152,"column":200}}}))
    + "\"></a></td>\n            <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"message_count",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":153,"column":25},"end":{"line":153,"column":95}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"message_count",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":153,"column":132},"end":{"line":153,"column":200}}}))
    + "\"></a></td>\n            <td></td>\n            <td><a href=\""
    + alias3((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"clients",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":155,"column":25},"end":{"line":155,"column":89}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias3((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias2).call(alias1,"channel",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"topic_name") : depth0),(depth0 != null ? lookupProperty(depth0,"channel_name") : depth0),"clients",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":155,"column":126},"end":{"line":155,"column":188}}}))
    + "\"></a></td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":156,"column":12},"end":{"line":160,"column":19}}})) != null ? stack1 : "")
    + "        </tr>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <td colspan=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0)) != null ? lookupProperty(stack1,"percentiles") : stack1)) != null ? lookupProperty(stack1,"length") : stack1), depth0))
    + "\">\n                <a href=\""
    + alias1((lookupProperty(helpers,"large_graph")||(depth0 && lookupProperty(depth0,"large_graph"))||alias3).call(alias2,"e2e",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0),"","e2e_processing_latency",{"name":"large_graph","hash":{},"data":data,"loc":{"start":{"line":158,"column":25},"end":{"line":158,"column":102}}}))
    + "\"><img width=\"120\" height=\"20\"  src=\""
    + alias1((lookupProperty(helpers,"sparkline")||(depth0 && lookupProperty(depth0,"sparkline"))||alias3).call(alias2,"e2e",(depth0 != null ? lookupProperty(depth0,"node") : depth0),(depth0 != null ? lookupProperty(depth0,"e2e_processing_latency") : depth0),"","e2e_processing_latency",{"name":"sparkline","hash":{},"data":data,"loc":{"start":{"line":158,"column":139},"end":{"line":158,"column":214}}}))
    + "\"></a>\n            </td>\n";
},"37":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"alert alert-warning\"><h4>Notice</h4>No clients connected to this channel</div>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <table class=\"table table-bordered table-condensed\">\n            <tr>\n                <th>Client Host</th>\n                <th>User-Agent</th>\n                <th>Attributes</th>\n                <th>NSQd Host</th>\n                <th>In-Flight</th>\n                <th>Ready Count</th>\n                <th>Finished</th>\n                <th>Requeued</th>\n                <th>Messages</th>\n                <th>Connected</th>\n            </tr>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"clients") : depth0),{"name":"each","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":188,"column":12},"end":{"line":221,"column":21}}})) != null ? stack1 : "")
    + "        </table>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <tr>\n                <td title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"remote_address") || (depth0 != null ? lookupProperty(depth0,"remote_address") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"remote_address","hash":{},"data":data,"loc":{"start":{"line":190,"column":27},"end":{"line":190,"column":45}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"hostname_port") || (depth0 != null ? lookupProperty(depth0,"hostname_port") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"hostname_port","hash":{},"data":data,"loc":{"start":{"line":190,"column":47},"end":{"line":190,"column":64}}}) : helper)))
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"show_client_id") : depth0),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":190,"column":64},"end":{"line":190,"column":109}}})) != null ? stack1 : "")
    + "</td>\n                <td>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"user_agent") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":191,"column":20},"end":{"line":191,"column":81}}})) != null ? stack1 : "")
    + "</td>\n                <td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"sample_rate") : depth0),{"name":"if","hash":{},"fn":container.program(45, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":193,"column":20},"end":{"line":195,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tls") : depth0),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":196,"column":20},"end":{"line":198,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"deflate") : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":199,"column":20},"end":{"line":201,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"snappy") : depth0),{"name":"if","hash":{},"fn":container.program(52, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":202,"column":20},"end":{"line":204,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"authed") : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":205,"column":20},"end":{"line":211,"column":27}}})) != null ? stack1 : "")
    + "                </td>\n                <td><a class=\"link\" href=\""
    + alias4((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||alias2).call(alias1,"/nodes",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":213,"column":42},"end":{"line":213,"column":63}}}))
    + "/"
    + alias4(((helper = (helper = lookupProperty(helpers,"node") || (depth0 != null ? lookupProperty(depth0,"node") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"node","hash":{},"data":data,"loc":{"start":{"line":213,"column":64},"end":{"line":213,"column":72}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"node") || (depth0 != null ? lookupProperty(depth0,"node") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"node","hash":{},"data":data,"loc":{"start":{"line":213,"column":74},"end":{"line":213,"column":82}}}) : helper)))
    + "</a></td>\n                <td>"
    + alias4((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"in_flight_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":214,"column":20},"end":{"line":214,"column":47}}}))
    + "</td>\n                <td>"
    + alias4((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"ready_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":215,"column":20},"end":{"line":215,"column":43}}}))
    + "</td>\n                <td>"
    + alias4((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"finish_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":216,"column":20},"end":{"line":216,"column":44}}}))
    + "</td>\n                <td>"
    + alias4((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"requeue_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":217,"column":20},"end":{"line":217,"column":45}}}))
    + "</td>\n                <td>"
    + alias4((lookupProperty(helpers,"commafy")||(depth0 && lookupProperty(depth0,"commafy"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"message_count") : depth0),{"name":"commafy","hash":{},"data":data,"loc":{"start":{"line":218,"column":20},"end":{"line":218,"column":45}}}))
    + "</td>\n                <td>"
    + alias4((lookupProperty(helpers,"nanotohuman")||(depth0 && lookupProperty(depth0,"nanotohuman"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"connected") : depth0),{"name":"nanotohuman","hash":{},"data":data,"loc":{"start":{"line":219,"column":20},"end":{"line":219,"column":45}}}))
    + "</td>\n            </tr>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " ("
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"client_id") || (depth0 != null ? lookupProperty(depth0,"client_id") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"client_id","hash":{},"data":data,"loc":{"start":{"line":190,"column":88},"end":{"line":190,"column":101}}}) : helper)))
    + ")";
},"43":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<small>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"user_agent") || (depth0 != null ? lookupProperty(depth0,"user_agent") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"user_agent","hash":{},"data":data,"loc":{"start":{"line":191,"column":52},"end":{"line":191,"column":66}}}) : helper)))
    + "</small>";
},"45":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"label label-info\">Sampled "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"sample_rate") || (depth0 != null ? lookupProperty(depth0,"sample_rate") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"sample_rate","hash":{},"data":data,"loc":{"start":{"line":194,"column":63},"end":{"line":194,"column":78}}}) : helper)))
    + "%</span>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"label label-warning\" "
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tls_version") : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":197,"column":58},"end":{"line":197,"column":201}}})) != null ? stack1 : "")
    + ">TLS</span>\n";
},"48":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"tls_version") || (depth0 != null ? lookupProperty(depth0,"tls_version") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_version","hash":{},"data":data,"loc":{"start":{"line":197,"column":84},"end":{"line":197,"column":99}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"tls_cipher_suite") || (depth0 != null ? lookupProperty(depth0,"tls_cipher_suite") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_cipher_suite","hash":{},"data":data,"loc":{"start":{"line":197,"column":100},"end":{"line":197,"column":120}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"tls_negotiated_protocol") || (depth0 != null ? lookupProperty(depth0,"tls_negotiated_protocol") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_negotiated_protocol","hash":{},"data":data,"loc":{"start":{"line":197,"column":121},"end":{"line":197,"column":148}}}) : helper)))
    + " mutual:"
    + alias4(((helper = (helper = lookupProperty(helpers,"tls_negotiated_protocol_is_mutual") || (depth0 != null ? lookupProperty(depth0,"tls_negotiated_protocol_is_mutual") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tls_negotiated_protocol_is_mutual","hash":{},"data":data,"loc":{"start":{"line":197,"column":156},"end":{"line":197,"column":193}}}) : helper)))
    + "\"";
},"50":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"label label-default\">Deflate</span>\n";
},"52":function(container,depth0,helpers,partials,data) {
    return "                        <span class=\"label label-primary\">Snappy</span>\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <span class=\"label label-success\">\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"auth_identity_url") : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":207,"column":24},"end":{"line":207,"column":88}}})) != null ? stack1 : "")
    + "\n                        <span class=\"glyphicon glyphicon-user white\" title=\"Authed"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"auth_identity") : depth0),{"name":"if","hash":{},"fn":container.program(57, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":208,"column":82},"end":{"line":208,"column":137}}})) != null ? stack1 : "")
    + "\"></span>\n                        "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"auth_identity_url") : depth0),{"name":"if","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":209,"column":24},"end":{"line":209,"column":60}}})) != null ? stack1 : "")
    + "\n                        </span>\n";
},"55":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"auth_identity_url") || (depth0 != null ? lookupProperty(depth0,"auth_identity_url") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"auth_identity_url","hash":{},"data":data,"loc":{"start":{"line":207,"column":58},"end":{"line":207,"column":79}}}) : helper)))
    + "\">";
},"57":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " Identity:"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"auth_identity") || (depth0 != null ? lookupProperty(depth0,"auth_identity") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"auth_identity","hash":{},"data":data,"loc":{"start":{"line":208,"column":113},"end":{"line":208,"column":130}}}) : helper)));
},"59":function(container,depth0,helpers,partials,data) {
    return "</a>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"warning"),depth0,{"name":"warning","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"error"),depth0,{"name":"error","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n<ol class=\"breadcrumb\">\n  <li><a class=\"link\" href=\""
    + alias3((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||alias2).call(alias1,"/",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":5,"column":28},"end":{"line":5,"column":44}}}))
    + "\">Streams</a>\n  <li><a class=\"link\" href=\""
    + alias3((lookupProperty(helpers,"basePath")||(depth0 && lookupProperty(depth0,"basePath"))||alias2).call(alias1,"/topics",{"name":"basePath","hash":{},"data":data,"loc":{"start":{"line":6,"column":28},"end":{"line":6,"column":50}}}))
    + "/"
    + alias3((lookupProperty(helpers,"urlencode")||(depth0 && lookupProperty(depth0,"urlencode"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"topic") : depth0),{"name":"urlencode","hash":{},"data":data,"loc":{"start":{"line":6,"column":51},"end":{"line":6,"column":70}}}))
    + "\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"topic") || (depth0 != null ? lookupProperty(depth0,"topic") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"topic","hash":{},"data":data,"loc":{"start":{"line":6,"column":72},"end":{"line":6,"column":81}}}) : helper)))
    + "</a>\n  <li class=\"active\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":7,"column":21},"end":{"line":7,"column":29}}}) : helper)))
    + "</li>\n</ol>\n\n<div class=\"row\">\n    <div class=\"col-md-6\">\n        <blockquote>\n            <p>Topic: <strong>"
    + alias3(((helper = (helper = lookupProperty(helpers,"topic") || (depth0 != null ? lookupProperty(depth0,"topic") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"topic","hash":{},"data":data,"loc":{"start":{"line":13,"column":30},"end":{"line":13,"column":39}}}) : helper)))
    + "</strong>\n            <p>Channel: <strong>"
    + alias3(((helper = (helper = lookupProperty(helpers,"name") || (depth0 != null ? lookupProperty(depth0,"name") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data,"loc":{"start":{"line":14,"column":32},"end":{"line":14,"column":40}}}) : helper)))
    + "</strong>\n        </blockquote>\n    </div>\n</div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"nodes") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":19,"column":0},"end":{"line":166,"column":11}}})) != null ? stack1 : "")
    + "\n<h4>Client Connections</h4>\n\n<div class=\"row\">\n    <div class=\"col-md-12\">\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"clients") : depth0)) != null ? lookupProperty(stack1,"length") : stack1),{"name":"unless","hash":{},"fn":container.program(37, data, 0, blockParams, depths),"inverse":container.program(39, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":172,"column":8},"end":{"line":223,"column":19}}})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});
